import classes from "./index.module.css";
import { MouseEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToggle } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  PaperProps,
  Button,
  Checkbox,
  Anchor,
  Stack,
  ButtonProps,
  Alert,
  Flex,
  Divider,
  LoadingOverlay,
  Title,
} from "@mantine/core";
import { Turnstile } from "@marsidev/react-turnstile";
import { GoogleIcon } from "../../components/icons";
import { Container } from "../../components/Container";
import { useSupabaseClient, useSession } from "@supabase/auth-helpers-react";
import { useSearchParams } from "react-router-dom";
import { errorTranslation } from "../../lib/translations";

type ValidProviders = "google";

const GoogleButton = (
  props: ButtonProps & { onClick: (e: MouseEvent) => void }
) => (
  <Button
    leftSection={<GoogleIcon />}
    variant="default"
    color="gray"
    {...props}
  />
);
const REGISTER = true;
export function Login(props: PaperProps) {
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [captchaToken, setCaptchaToken] = useState<string | undefined>();
  const [type, toggle] = useToggle(["login", "register"]);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const supabase = useSupabaseClient();
  const session = useSession();

  useEffect(() => {
    if (!REGISTER) {
      toggle("login");
      return;
    }
    if (searchParams && searchParams.has("t")) {
      const t = searchParams.get("t");
      if (t === "login" || t === "register") {
        toggle(t);
      }
    }
  }, [searchParams, toggle]);

  useEffect(() => {
    if (session) {
      navigate("/app");
    }
  }, [session, navigate]);

  const form = useForm({
    initialValues: {
      email: "",
      confirm: "",
      password: "",
      terms: true,
    },

    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : "Email inválido"),
      password: (val) =>
        val.length <= 6
          ? "Senha deve conter ao menos 6 letras ou dígitos"
          : null,
      confirm: (val, { password }) => {
        if (type === "login") return null;
        return val === password
          ? null
          : "Confirmação de senha e senha não correspondem";
      },
    },
  });

  const login = async () => {
    setLoading(true);
    const { data, error } = await supabase.auth.signInWithPassword({
      email: form.values.email,
      password: form.values.password,
      options: { captchaToken },
    });
    setLoading(false);
    if (error) {
      setError(errorTranslation(error.message));
    } else if (data.session) {
      setError(undefined);
      return navigate("/app");
    }
  };

  const socialLogin = async (provider: ValidProviders) => {
    setLoading(true);
    const { error } = await supabase.auth.signInWithOAuth({
      provider,
    });
    setLoading(false);
    if (error) {
      setError(String(error.status));
    } else {
      return navigate("/app");
    }
  };

  const register = async () => {
    if (!REGISTER) {
      return false;
    }
    setLoading(true);
    const { data, error } = await supabase.auth.signUp({
      email: form.values.email,
      password: form.values.password,
      // options: { captchaToken },
    });
    setLoading(false);
    if (error) {
      setError(String(error.status));
    } else if (data.user) {
      return navigate("/verify-email");
    }
  };

  const handleFormSubmit = () => {
    return type === "login" ? login() : register();
  };

  return (
    <Container>
      <Flex
        align="center"
        justify="center"
        h="100%"
        direction="column"
        gap={"xl"}
      >
        <Title ta="center" className={classes.title} fw={900}>
          Bem vindo de volta!
        </Title>
        <Paper
          radius="md"
          p="xl"
          className={classes.paper}
          withBorder
          {...props}
        >
          <LoadingOverlay
            visible={loading}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
          />
          <Text size="xl">
            {type === "login"
              ? "Acesse sua conta Instrumentar"
              : "Crie sua conta"}
          </Text>

          <Group grow mb="md" mt="md">
            <GoogleButton onClick={() => socialLogin("google")} radius="xl">
              Conectar com Google
            </GoogleButton>
          </Group>

          <Divider
            label="Ou continue usando seu email"
            labelPosition="center"
            my="lg"
          />

          <form onSubmit={form.onSubmit(handleFormSubmit)}>
            <Stack>
              <TextInput
                required
                label="Email"
                type="email"
                name="email"
                placeholder="meuemail@exemplo.com"
                value={form.values.email}
                onChange={(event) =>
                  form.setFieldValue("email", event.currentTarget.value)
                }
                error={form.errors.email && "Email inválido"}
                radius="md"
              />

              <PasswordInput
                required
                label="Senha"
                placeholder="Sua senha"
                type="password"
                name="password"
                value={form.values.password}
                onChange={(event) =>
                  form.setFieldValue("password", event.currentTarget.value)
                }
                error={
                  form.errors.password &&
                  "Senha deve conter ao menos 6 letras ou dígitos"
                }
                radius="md"
              />

              {type === "register" && (
                <PasswordInput
                  required
                  label="Confirme sua senha"
                  placeholder="Sua senha"
                  value={form.values.confirm}
                  onChange={(event) =>
                    form.setFieldValue("confirm", event.currentTarget.value)
                  }
                  error={
                    form.errors.confirm &&
                    "Confirmação de senha e senha não correspondem"
                  }
                  radius="md"
                />
              )}

              {type === "register" && (
                <Checkbox
                  label="Eu aceito os termos e condições de uso"
                  checked={form.values.terms}
                  onChange={(event) =>
                    form.setFieldValue("terms", event.currentTarget.checked)
                  }
                />
              )}
              {error && <Alert color="red">{error}</Alert>}
            </Stack>

            <Group justify="center" mt="xl">
              <Turnstile
                siteKey="0x4AAAAAAAM4NFMwdqH6Jvdr"
                onSuccess={(token) => setCaptchaToken(token)}
                options={{ theme: "light" }}
              />
            </Group>

            <Group justify="space-between" mt="xl">
              {REGISTER && (
                <Anchor
                  component="button"
                  type="button"
                  color="dimmed"
                  onClick={() => toggle()}
                  size="xs"
                >
                  {type === "register"
                    ? "Já tem uma conta? Entrar"
                    : "Não tem uma conta? Cadastrar"}
                </Anchor>
              )}
              <Button type="submit" fullWidth>
                {type === "login" ? "Entrar" : "Cadastrar"}
              </Button>
            </Group>
          </form>
        </Paper>
      </Flex>
    </Container>
  );
}
